//
// (Explicit Dependencies) Import UXCore2 components.
//
import Accordion from '@ux/accordion';
import Alert from '@ux/alert';
import Button from '@ux/button';
import ButtonSet from '@ux/button-set';
import Checkbox from '@ux/checkbox';
import CheckboxGroup from '@ux/checkbox-group';
import Collapsible from '@ux/collapsible';
import Component from '@ux/component';
import * as containers from '@ux/containers';
import Criteria from '@ux/criteria';
import Dropdown from '@ux/dropdown';
import Fieldset from '@ux/fieldset';
import Form from '@ux/form';
import FormElement from '@ux/form-element';
import FormGroup from '@ux/form-group';
import Growl from '@ux/growl';
import Icon from '@ux/icon';
import MessageOverlay from '@ux/message-overlay';
import Modal from '@ux/modal';
import * as NamespaceComponent from '@ux/namespace-component';
import Pagination from '@ux/pagination';
import Password from '@ux/password';
import Pivots from '@ux/pivot';
import ProgressBar from '@ux/progress-bar';
import ProgressSteps from '@ux/progress-steps';
import QuantitySelector from '@ux/quantity-selector';
import Radio from '@ux/radio';
import RadioGroup from '@ux/radio-group';
import Remaining from '@ux/remaining';
import Search from '@ux/search';
import Spinner from '@ux/spinner';
import Stars from '@ux/stars';
import Table from '@ux/table';
import Tabs from '@ux/tabs';
import TelephoneInput from '@ux/telephone-input';
import Toggle from '@ux/toggle';
import Tooltip from '@ux/tooltip';
import request from '@godaddy/request';
import Transition from '@ux/transition';
import trfq from 'react-trfq';
import * as utils from '@ux/util';
import * as Validation from 'react-validation-context';
import * as styles from '@ux/inline-styles';

const versions = {
  [process.env.BUNDLE_NAME]: process.env.BUNDLE_VERSION
};

// Babel7 doesn't attach __esModule onto `import *`s even if they are actually es modules.
// For now, manually attach them for compatibility
[utils, styles, containers, NamespaceComponent].forEach(function (thing) {
  Object.defineProperty(thing, '__esModule', {
    value: true
  });
});

//
// Also export Components to allow other applications depend directly on
// UXCore2. This cannot be done dynamically by destructuring the components as
// this would violate the assumption of statically defined imports/exports.
//
// Note: do NOT export as default, it will prevent `{ [Component] }` like imports.
//
export {
  Accordion,
  Alert,
  Button,
  ButtonSet,
  Checkbox,
  CheckboxGroup,
  Collapsible,
  Component,
  containers,
  Criteria,
  Dropdown,
  Fieldset,
  Form,
  FormElement,
  FormGroup,
  Growl,
  Icon,
  MessageOverlay,
  Modal,
  NamespaceComponent,
  Pagination,
  Password,
  Pivots,
  ProgressBar,
  ProgressSteps,
  QuantitySelector,
  Radio,
  RadioGroup,
  Remaining,
  Search,
  Spinner,
  Stars,
  Table,
  Tabs,
  TelephoneInput,
  Toggle,
  Tooltip,
  Transition,
  request,
  styles,
  trfq,
  utils,
  Validation,
  versions
};
