(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("react-transition-group")["default"], require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "react-transition-group", "react-dom"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("prop-types"), require("react"), require("react-transition-group")["default"], require("react-dom")) : factory(root["PropTypes"], root["React"], root["ux"]["ReactTransitionGroup"], root["ReactDOM"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__49__) {
return 